var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%", "padding-top": "15px" },
      attrs: { data: _vm.list }
    },
    [
      _c("el-table-column", {
        attrs: { label: _vm.$t("dashboard.orderId"), "min-width": "150" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("orderNoFilter")(scope.row.orderId)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("user.playerId"), "min-width": "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v("\n      " + _vm._s(scope.row.username) + "\n    ")
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("dashboard.timestamp"), "min-width": "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("timestampFilter")(scope.row.timestamp)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("dashboard.status"),
          width: "100",
          align: "center"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm._f("transactionStatusFilter")(row.status)
                    }
                  },
                  [_vm._v("\n        " + _vm._s(row.status) + "\n      ")]
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("dashboard.price"),
          width: "195",
          align: "center"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      $" +
                    _vm._s(_vm._f("toThousandFilter")(scope.row.price)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("dashboard.productId"), align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v("\n      " + _vm._s(scope.row.productId) + "\n    ")
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("dashboard.type"), align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v("\n      " + _vm._s(scope.row.type) + "\n    ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("dashboard.count"), align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_vm._v("\n      " + _vm._s(scope.row.count) + "\n    ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }